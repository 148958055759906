/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core';
import { FolderOpen, ListAlt, PersonRounded } from '@material-ui/icons';

import { Pagination } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { getProjects } from '../config/projectsApi';
import { saveProjects, saveSelectedProjectId, saveSelectedTaskState } from '../actions/projectsActions';
import { useAuth } from '../components/AuthProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    marginRight: '0.3vw',
  },
  pagination: {
    '& > *': {
      display: 'flex',
      justifyContent: 'center',
      // marginTop: theme.spacing(1),
    },
  },
  item: {
    width: '482px',
    height: '100%',
    padding: '8vh',
  },
  title: {
    marginTop: '2vw',
    marginLeft: '1.7em',
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '25px',
    lineHeight: '35px',
    color: '#0E2040',
  },
  box: {
    padding: '2vh',
    backgroundColor: 'white',
  },
  projectName: {
    fontFamily: 'Inter',
    fontSize: '25px',
    fontWeight: 'bold',
    lineHeight: '30.26px',
    color: '#000000',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  status: {
    padding: '2px',
    borderRadius: '0.5vw',
    fontFamily: 'Inter',
    fontSize: '20px',
    textAlign: 'center',
    backgroundColor: '#83C29D33',
    color: '#21BD1E',
  },
  projectContent: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '400',
  },
  linkColor: {
    color: 'black',
  },
  iconGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  containerProjects: {
    paddingRight: '2vw',
    width: '100%',
    height: '75vh',
    overflow: 'hidden',
  },
  column: {
    height: '100%',
    margin: '1vw',
    overflowY: 'auto'
  },
  newStatus: {
    backgroundColor: '#d9eaf7', // Light Blue
    color: '#007BFF', // Blue text
  },
  completedStatus: {
    backgroundColor: '#d6f5d6', // Light Green
    color: '#21BD1E', // Green text
  },
  inProgressStatus: {
    backgroundColor: '#fff4d6', // Light Orange
    color: '#FF9500', // Orange text
  },
  pendingStatus: {
    backgroundColor: '#f5d6d6', // Light Red
    color: '#FF0000', // Red text
  },
  new: {
    color: '#007BFF', // Blue text
  },
  completed: {
    color: '#21BD1E', // Green text
  },
  inProgress: {
    color: '#FF9500', // Orange text
  },
  pending: {
    color: '#FF0000', // Red text
  },
  divider: {
    border: '0.5px solid black',
    width: '100%',
  },
  listItems: {
    fontSize: '0.8rem',
  },
  all: {
    color: 'red',
  }
}));

function Projects({ searchQuery }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authRefreshToken } = useAuth();

  // add useRef to avoid 2 calls for the api
  const hasFetched = useRef(false);
  const [projectsMetaData, setProjectsMetaData] = useState({});
  const [paginationPage, setPaginationPage] = useState(1);
  const [responseMessage, setResponseMessage] = useState('');

  const token = useSelector((state) => state.account.token);
  const stateProjects = useSelector((state) => state.projects.projects);

  // retrieve token from local storage
  const saveToken = localStorage.getItem('token');

  const savedRtl = localStorage.getItem('rtl');

  const projectsFetch = async (page, name) => {
    const comingProjectsResponse = await getProjects(!token ? saveToken : token, page, name);

    if (comingProjectsResponse.status === 200) {
      // save projects to state
      dispatch(saveProjects([...comingProjectsResponse.data.data]));

      // Save projects metatdata to state
      setProjectsMetaData({ ...comingProjectsResponse.data.metadata });

      // save response message
      setResponseMessage(comingProjectsResponse.data.message);
    } else if (comingProjectsResponse.status === 401) {
      authRefreshToken(true);
    }
  };

  const handlePaginationChange = (event, newValue) => {
    setPaginationPage(newValue);
    projectsFetch(newValue);
  };

  const handleProjectTasks = (event, project, state) => {
    // save project id to state
    dispatch(saveSelectedProjectId(project.id));
    // save project id to local storage
    localStorage.setItem('projectId', project.id);

    // save selected project task state to state
    dispatch(saveSelectedTaskState(state));
    // save selected project task state to local storage
    localStorage.setItem('selectedTasksState', state);

    navigate(`/project/${project.name}/tasks`);
  };

  useEffect(() => {
    if (searchQuery && searchQuery.length > 0) {
      // If there is a search query, fetch tasks with the query
      console.log('Fetching tasks with query:', searchQuery);
      projectsFetch(paginationPage, searchQuery);
    } else if ((searchQuery === '' && searchQuery.length === 0) || !hasFetched.current) {
      // If the search query is empty and we haven't fetched yet, fetch tasks without the query
      console.log('Fetching tasks without query');
      hasFetched.current = true; // Mark as fetched
      projectsFetch(paginationPage); // Fetch without the query
    }
  }, [searchQuery, paginationPage]);

  return (
    <>
      {responseMessage === 'No projects available according to your filtration.' ? (
        <div className={classes.containerProjectTasks}>
          <Grid container>
            <Grid item xs={12} lg={12} md={12}>
              <Box>
                <Typography className={classes.title}>
                  {responseMessage}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      ) : (
        <>
          {responseMessage === '' || stateProjects.length === 0 ? (
            <LinearProgress />
          ) : (
            <>
              <Grid item xs={12} lg={12} md={12}>
                <Typography className={classes.title}>
                  {savedRtl === 'true' ? 'جميع المشاريع' : 'All Projects'}
                </Typography>
              </Grid>
              <div className={classes.containerProjects}>
                <div className={classes.column}>
                  <Grid container spacing={5}>

                    {stateProjects && stateProjects.map((project, index) => (
                      <Grid
                        item
                        key={project.id || index}
                        className={classes.item}
                        xs={12}
                        sm={12}
                        lg={4}
                        xl={4}
                        md={6}
                      >
                        <Box className={classes.box}>
                          <Grid container xs={12}>
                            <Grid item xs={8}>
                              <Typography variant="h5" className={`${classes.projectName}`} component="h2">
                                {project.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography className={`${classes.status} ${project.state === 'New' ? classes.newStatus : project.state === 'Completed' ? classes.completedStatus : project.state === 'In Progress' ? classes.inProgressStatus : classes.pendingStatus}`}>
                                {project.state}
                              </Typography>
                            </Grid>
                            <hr className={classes.divider} />
                            <Grid item xs={8}>
                              <List>
                                <ListItem>
                                  <ListItemText primary={(
                                    <Typography variant="body2" className={`${classes.projectContent} arabic-style`}>
                                      {savedRtl === 'true' ? 'تاريخ البدء:' : 'Start Date:'}
                                      {' '}
                                      {project.start_date ? project.start_date : '---'}
                                    </Typography>
                          )}
                                  />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary={(
                                    <Typography variant="body2" color="error" className={`${classes.projectContent} arabic-style`}>
                                      {savedRtl === 'true' ? 'تاريخ الانتهاء:' : 'Deadline:'}
                                      {' '}
                                      {project.start_end ? project.start_end : '---'}
                                    </Typography>
                          )}
                                  />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary={(
                                    <>
                                      <div className={`${classes.iconGroup} arabic-style`}>
                                        <Typography>{savedRtl === 'true' ? 'المدير:' : 'Manager:'}</Typography>
                                        <PersonRounded />
                                        {project.manager_id ? project.manager_id : '---'}
                                      </div>
                                    </>
                          )}
                                  />
                                </ListItem>

                              </List>
                            </Grid>
                            <Grid item xs={4}>
                              <List>
                                <ListItem
                                  className="arabic-style"
                                  button
                                  disabled={project.tasks.all === 0}
                                  onClick={(event) => handleProjectTasks(event, project, 'all')}
                                >
                                  <ListAlt className={classes.all} />
                                  <ListItemText className={classes.listItems} primary={`${project.tasks.all} ${savedRtl === 'true' ? 'المهام' : 'Tasks'}`} />
                                </ListItem>
                                <ListItem
                                  button
                                  disabled={project.tasks.c_pending === 0}
                                  onClick={(event) => handleProjectTasks(event, project, 'Pending(Client)')}
                                >
                                  <ListItemIcon>
                                    <FolderOpen className={classes.new} />
                                    <ListItemText className={classes.listItems} secondary={`${project.tasks.c_pending} ${savedRtl === 'true' ? 'قيد الانتظار(العميل)' : 'Pending(client)'}`} />
                                  </ListItemIcon>
                                </ListItem>
                                <ListItem
                                  button
                                  disabled={project.tasks.pending === 0}
                                  onClick={(event) => handleProjectTasks(event, project, 'Pending(dmc)')}
                                >
                                  <ListItemIcon>
                                    <FolderOpen className={classes.new} />
                                    <ListItemText className={classes.listItems} secondary={`${project.tasks.pending} ${savedRtl === 'true' ? 'قيد الانتظار(المدير)' : 'Pending(dmc)'}`} />
                                  </ListItemIcon>
                                </ListItem>
                                <ListItem
                                  button
                                  disabled={project.tasks.in_progress === 0}
                                  onClick={(event) => handleProjectTasks(event, project, 'In Progress')}
                                >
                                  <ListItemIcon>
                                    <FolderOpen className={classes.inProgress} />
                                    <ListItemText className={classes.listItems} secondary={`${project.tasks.in_progress} ${savedRtl === 'true' ? 'قيد التنفيذ' : 'In Progress'}`} />
                                  </ListItemIcon>
                                </ListItem>
                                <ListItem
                                  button
                                  disabled={project.tasks.done === 0}
                                  onClick={(event) => handleProjectTasks(event, project, 'Completed')}
                                >
                                  <ListItemIcon>
                                    <FolderOpen className={classes.completed} />
                                    <ListItemText className={classes.listItems} secondary={`${project.tasks.done} ${savedRtl === 'true' ? 'مكتملة' : 'Completed'}`} />
                                  </ListItemIcon>
                                </ListItem>
                              </List>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    ))}

                  </Grid>
                </div>
              </div>

              <Grid item xs={12} className={classes.pagination}>
                <Pagination
                  style={{ direction: savedRtl === 'true' ? 'ltr' : 'ltr' }}
                  variant="outlined"
                  shape="rounded"
                  color="#007BFF"
                  size="large"
                  count={projectsMetaData.pages}
                  page={paginationPage}
                  onChange={handlePaginationChange}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}

// Define prop types for Projects component
Projects.propTypes = {
  searchQuery: PropTypes.string.isRequired, // Expecting a string for searchQuery
};

export default Projects;
