/* eslint-disable react/prop-types */
import React, { createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';

import {
  Logout,
  saveRefreshToken, saveRefreshTokenExpiryTime, saveToken, saveTokenExpiryTime,
  saveUserDetails
} from '../actions/accountActions';
import { BASE_URL } from '../config';
import { refreshTokenAccess, revokeToken } from '../config/accountApi';

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const currentDate = new Date();

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();

  const authLogin = (successLoginResponse) => {
    localStorage.setItem('isAuthUser', true);
    dispatch(Logout(true));

    // get token, tokenExpiryTime, refreshToken , refreshTokenExpiryTime from response
    const accessToken = successLoginResponse.data.data[0].access_token;
    const accessTokenExpiryTime = successLoginResponse.data.data[0].token_expiry_time;
    const refreshToken = successLoginResponse.data.data[0].refresh_token;
    const refreshTokenExpiryTime = successLoginResponse.data.data[0].refresh_token_expiry_time;

    // save token, tokenExpiryTime, refreshToken , refreshTokenExpiryTime to state
    dispatch(saveToken(accessToken));
    dispatch(saveTokenExpiryTime(accessTokenExpiryTime));
    dispatch(saveRefreshToken(refreshToken));
    dispatch(saveRefreshTokenExpiryTime(refreshTokenExpiryTime));

    // save token to local storage
    localStorage.setItem('token', accessToken);
    localStorage.setItem('tokenExpiryTime', accessTokenExpiryTime);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('refreshTokenExpiryTime', refreshTokenExpiryTime);

    // save user details to state
    const userDetails = successLoginResponse.data.data[0].current_user;
    dispatch(saveUserDetails(userDetails));
    localStorage.setItem('userDetails', JSON.stringify(userDetails));

    // save user image, role and name to local storage
    localStorage.setItem('role', userDetails.role);
    localStorage.setItem('userImageUrl', `${BASE_URL}${userDetails.image}`);
    localStorage.setItem('userName', userDetails.name);
  };

  const authLogout = () => {
    dispatch(Logout(false));

    const savedToken = localStorage.getItem('token');
    revokeToken(savedToken);

    // remove all data from local storage
    Object.keys(localStorage).forEach((key) => {
      if (key !== 'rtl') {
        localStorage.removeItem(key);
      }
    });
  };

  const authRefreshToken = async (refreshTokenError = false) => {
    const expiryRefreshTokenTime = localStorage.getItem('refreshTokenExpiryTime');
    const remainingRefreshTokenTime = new Date(expiryRefreshTokenTime);

    if (currentDate > remainingRefreshTokenTime) {
      // refresh Token is not valid, need to log in again
      localStorage.setItem('isAuthUser', false);
      localStorage.removeItem('token');
      dispatch(Logout(false));
    } else {
      const expiryTokenTime = localStorage.getItem('tokenExpiryTime');
      const remainingTokenTime = new Date(expiryTokenTime);

      if (currentDate > remainingTokenTime) {
        const savedRefreshToken = localStorage.getItem('refreshToken');
        console.log('Token is expired, handle refresh');

        try {
          const newRefreshTokenResponse = await refreshTokenAccess(savedRefreshToken);
          const newAccessToken = newRefreshTokenResponse.data.data[0].access_token;
          const newAccessTokenExpiryTime = newRefreshTokenResponse.data.data[0].token_expiry_time;

          // save token to local storage
          localStorage.setItem('token', newAccessToken);
          localStorage.setItem('tokenExpiryTime', newAccessTokenExpiryTime);
          localStorage.setItem('isAuthUser', false);
          dispatch(Logout(false));
        } catch (error) {
          // if error, need to log in again
          console.log('refresh token response error', error);
          authLogout();
        }
      }
      // Token is still valid, no need to refresh token
      localStorage.setItem('isAuthUser', true);
      dispatch(Logout(true));
    }

    if (refreshTokenError) {
      // if error, need to log in again
      console.log('refresh token error');
      authLogout();
    }
  };

  return (
    <AuthContext.Provider value={{
      authLogin, authRefreshToken, authLogout
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
