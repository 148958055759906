import React, { useEffect, useState } from 'react';
import {
  Routes, Route, Navigate,
  useLocation
} from 'react-router-dom';

import { useSelector } from 'react-redux';
import Login from './features/Login';
import AuthProvider from './components/AuthProvider';
import MainComponent from './components/MainComponent';
import Projects from './features/Projects';
import Tasks from './features/Tasks';

import ProjectTasks from './features/ProjectTasks';
import TaskDetails from './features/TaskDetails';
import Dashboard from './features/Dashboard';
import Profile from './features/Profile';
import './style/App.css';

function App() {
  const location = useLocation();
  const logout = useSelector((state) => state.account.logout);
  const [searchQuery, setSearchQuery] = useState('');
  const [rtl, setRtl] = useState(false);

  const handleRtlChange = (language) => {
    const newRtl = language === 'AR';
    setRtl(newRtl);
    localStorage.setItem('rtl', newRtl);
  };
  const savedRtl = localStorage.getItem('rtl');

  const isAuthenticated = () => {
    const isAuthUser = localStorage.getItem('isAuthUser');
    const token = localStorage.getItem('token');
    return logout || (isAuthUser && token !== null);
  };

  useEffect(() => {
    isAuthenticated();
  }, [localStorage.getItem('isAuthUser'), localStorage.getItem('token')]);

  return (
    <div className={(location.pathname === '/auth' || location.pathname === '/') ? (rtl || savedRtl === 'true' ? 'rtl' : '') : (rtl || savedRtl === 'true' ? 'rtl App' : 'App')}>
      <Routes>
        <Route
          path="/"
          element={isAuthenticated()
            ? <Navigate to="/dashboard" /> : <Navigate to="/auth" replace />}
        />
        <Route element={isAuthenticated() ? <MainComponent searchQuery={searchQuery} rtl={rtl} handleRtlChange={handleRtlChange} setSearchQuery={setSearchQuery} /> : <Navigate to="/auth" replace />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/projects" element={<Projects searchQuery={searchQuery} />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/tasks" element={<Tasks searchQuery={searchQuery} />} />
          <Route path="/project/:projectId/tasks" element={<ProjectTasks searchQuery={searchQuery} />} />
          <Route path="/tasks/:taskId/details" element={<TaskDetails />} />
        </Route>
        <Route path="/auth" element={isAuthenticated() ? <Navigate to="/dashboard" /> : <Login />} />
      </Routes>
    </div>
  );
}

const AppWrapper = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default AppWrapper;
