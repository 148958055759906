/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Card, Grid, IconButton, Menu
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dashboard, FolderOpen, Image, MoreHoriz, Settings
} from '@material-ui/icons';
import { Link, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import Navbar from './Navbar';
import { useAuth } from './AuthProvider';

const useStyles = makeStyles((theme) => ({
  nav: {
    marginTop: '2vh',
    backgroundColor: theme.palette.background.paper,
    paddingTop: '5vh',
    height: '83vh',
  },
  sideNav: {
    paddingTop: '0.5vh',
  },
  sideNavItem: {
    marginBottom: '0.5em',
  },
  linkColor: {
    color: 'black',
    textDecoration: 'none',
  },
  selected: {
    backgroundColor: '#d9eaf7 !important', // Light Blue,
  },
  sectionDesktop: {
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  sectionMobile: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  alwaysShow: {
    display: 'block',
  }
}));

function MainComponent({
  searchQuery, setSearchQuery, rtl, handleRtlChange
}) {
  const classes = useStyles();
  const { authRefreshToken } = useAuth();

  // add useRef to avoid 2 calls for the api
  const hasFetched = useRef(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const mobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // retrieve selectedIndex in side nav  from local storage
  const newSelectedIndex = localStorage.getItem('selectedIndex');
  const savedRtl = localStorage.getItem('rtl');

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleListItemClick = (event, newValue) => {
    // save selectedIndex in side nav to local storage
    localStorage.setItem('selectedIndex', newValue);

    // clear search status while navigation on sidenav
    setSearchQuery('');
  };

  const handleMobileClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const renderMobileSideNav = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id="account-menu-mobile"
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={mobileMenuOpen}
      onClose={handleMobileClose}
    >
      <List className={classes.nav}>
        <Link className={classes.linkColor} to="/">
          <ListItem
            className={`${classes.sideNavItem} arabic-style ${Number(newSelectedIndex) === 0 ? classes.selected : ''}`}
            button
            selected={Number(newSelectedIndex) === 0}
            onClick={(event) => { handleListItemClick(event, 0); handleMobileClose(); }}
          >
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary={savedRtl === 'true' ? 'لوحة التحكم' : 'Dashboard'} />
          </ListItem>
        </Link>
        <Link className={classes.linkColor} to="/projects">
          <ListItem
            className={`${classes.sideNavItem} arabic-style ${Number(newSelectedIndex) === 1 ? classes.selected : ''}`}
            button
            selected={Number(newSelectedIndex) === 1}
            onClick={(event) => { handleListItemClick(event, 1); handleMobileClose(); }}
          >
            <ListItemIcon>
              <FolderOpen />
            </ListItemIcon>
            <ListItemText primary={savedRtl === 'true' ? 'المشاريع' : 'Projects'} />
          </ListItem>
        </Link>
        <Link className={classes.linkColor} to="/tasks">
          <ListItem
            button
            className={`${classes.sideNavItem} arabic-style ${Number(newSelectedIndex) === 2 ? classes.selected : ''}`}
            selected={Number(newSelectedIndex) === 2}
            onClick={(event) => { handleListItemClick(event, 2); handleMobileClose(); }}
          >
            <ListItemIcon>
              <Image />
            </ListItemIcon>
            <ListItemText primary={savedRtl === 'true' ? 'المهام' : 'Tasks'} />
          </ListItem>
        </Link>
        <Link className={classes.linkColor} to="/profile">
          <ListItem
            button
            className={`${classes.sideNavItem} arabic-style ${Number(newSelectedIndex) === 3 ? classes.selected : ''}`}
            selected={Number(newSelectedIndex) === 3}
            onClick={(event) => { handleListItemClick(event, 3); handleMobileClose(); }}
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary={savedRtl === 'true' ? 'الإعدادات' : 'Settings'} />
          </ListItem>
        </Link>
      </List>
    </Menu>
  );

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      authRefreshToken();
    }
  }, []);

  return (
    <div>
      <Grid container spacing={12}>
        <Grid item lg={12} md={12} xs={12}>
          <Navbar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            rtl={rtl}
            handleRtlChange={handleRtlChange}
          />
        </Grid>

        <Grid item xs={12} lg={2} md={2} className={classes.sideNav}>
          <Card className={classes.sectionDesktop}>
            <List className={classes.nav}>
              <Link className={classes.linkColor} to="/">
                <ListItem
                  className={`${classes.sideNavItem} arabic-style ${Number(newSelectedIndex) === 0 ? classes.selected : ''}`}
                  button
                  selected={Number(newSelectedIndex) === 0}
                  onClick={(event) => handleListItemClick(event, 0)}
                >
                  <ListItemIcon>
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText primary={savedRtl === 'true' ? 'لوحة التحكم' : 'Dashboard'} />
                </ListItem>
              </Link>
              <Link className={classes.linkColor} to="/projects">
                <ListItem
                  className={`${classes.sideNavItem} arabic-style ${Number(newSelectedIndex) === 1 ? classes.selected : ''}`}
                  button
                  selected={Number(newSelectedIndex) === 1}
                  onClick={(event) => handleListItemClick(event, 1)}
                >
                  <ListItemIcon>
                    <FolderOpen />
                  </ListItemIcon>
                  <ListItemText primary={savedRtl === 'true' ? 'المشاريع' : 'Projects'} />
                </ListItem>
              </Link>
              <Link className={classes.linkColor} to="/tasks">
                <ListItem
                  button
                  className={`${classes.sideNavItem} arabic-style ${Number(newSelectedIndex) === 2 ? classes.selected : ''}`}
                  selected={Number(newSelectedIndex) === 2}
                  onClick={(event) => handleListItemClick(event, 2)}
                >
                  <ListItemIcon>
                    <Image />
                  </ListItemIcon>
                  <ListItemText primary={savedRtl === 'true' ? 'المهام' : 'Tasks'} />
                </ListItem>
              </Link>
              <Link className={classes.linkColor} to="/profile">
                <ListItem
                  button
                  className={`${classes.sideNavItem} arabic-style ${Number(newSelectedIndex) === 3 ? classes.selected : ''}`}
                  selected={Number(newSelectedIndex) === 3}
                  onClick={(event) => handleListItemClick(event, 3)}
                >
                  <ListItemIcon>
                    <Settings />
                  </ListItemIcon>
                  <ListItemText primary={savedRtl === 'true' ? 'الإعدادات' : 'Settings'} />
                </ListItem>
              </Link>
            </List>
          </Card>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls="account-menu-mobile"
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreHoriz />
            </IconButton>
          </div>
          {renderMobileSideNav}
        </Grid>

        <Grid className={classes.alwaysShow} item xs={12} lg={10} md={10}>
          <Outlet />
        </Grid>
      </Grid>
    </div>
  );
}

// Define prop types for Main component
MainComponent.propTypes = {
  searchQuery: PropTypes.string.isRequired, // Expecting a string for searchQuery
  setSearchQuery: PropTypes.func.isRequired, // Expecting a function for setSearchQuery
  rtl: PropTypes.bool.isRequired, // Expecting a boolean for rtl
  handleRtlChange: PropTypes.func.isRequired, // Expecting a function for handleRtlChange
};

export default MainComponent;
