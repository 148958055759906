import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  AssignmentInd, Email, Language, Phone, Public
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '2vw',
    minHeight: '100vh',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5vh',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  picture: {
    width: 120,
    height: 120,
    borderRadius: '50%',
    objectFit: 'cover',
    margin: theme.spacing(2, 'auto'),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 2, 0, 0),
    },
  },
  content: {
    flex: 1,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  name: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
  },
  bio: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  stats: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  stat: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    fontSize: '1rem',
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  title: {
    marginTop: '2vw',
    marginLeft: '1.7em',
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '25px',
    lineHeight: '35px',
    color: '#0E2040',
  },
}));

function Profile() {
  const classes = useStyles();

  // retrieve user image and name from local storage
  const savedUserDetails = JSON.parse(localStorage.getItem('userDetails'));
  const savedUserImageUrl = localStorage.getItem('userImageUrl');

  const savedRtl = localStorage.getItem('rtl');

  return (
    <div className={classes.containerProjectTasks}>
      <Grid container>
        <Grid item xs={12} lg={12} md={12}>
          <Box>
            <Typography className={classes.title}>
              {savedRtl === 'true' ? 'الملف الشخصي' : 'Profile'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={12} md={12}>
          <Card className={`${classes.card} arabic-style`}>
            <CardMedia
              className={classes.picture}
              image={savedUserImageUrl}
              title="Profile Picture"
            />
            <CardContent>
              <Typography className={classes.name} variant="h5" component="h2">
                {savedUserDetails.name}
              </Typography>
              <Typography className={classes.bio} variant="body1">
                <AssignmentInd fontSize="small" style={{ marginRight: 8 }} />
                {savedUserDetails.role === 'write' ? (savedRtl === 'true' ? 'مدير' : 'Admin') : (savedRtl === 'true' ? 'مستخدم' : 'User')}
              </Typography>
              <br />
              <Grid container className={classes.stats}>
                <Grid item xs={12} sm={12} md={12} className={classes.stat}>
                  <Typography className={classes.statValue} variant="body1">
                    <Email fontSize="small" style={{ marginRight: 8 }} />
                    {savedUserDetails.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={classes.stat}>
                  <Typography className={classes.statValue} variant="body1">
                    <Phone fontSize="small" style={{ marginRight: 8 }} />
                    {savedUserDetails.mobile === 'False' ? (savedRtl === 'true' ? 'غير متوفر' : 'Not Available') : savedUserDetails.mobile}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={classes.stat}>
                  <Typography className={classes.statValue} variant="body1">
                    <Public fontSize="small" style={{ marginRight: 8 }} />
                    {savedUserDetails.timezone}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={classes.stat}>
                  <Typography className={classes.statValue} variant="body1">
                    <Language fontSize="small" style={{ marginRight: 8 }} />
                    {savedUserDetails.language}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default Profile;
