/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Chart } from 'primereact/chart';

import { useDispatch, useSelector } from 'react-redux';
import { getDashboard } from '../config/accountApi';
import { saveDashboardData } from '../actions/accountActions';

const useStyles = makeStyles(() => ({
  container: {
    margin: '3vw',
    display: 'flex',
    flexDirection: 'column',
    gap: '2vw',
  },
  topCharts: {
    display: 'flex',
    gap: '0.5vw',
    marginBottom: '3vw',
    marginTop: '3vw',
  },
  scrollableSection: {
    margin: '1vw',
    paddingRight: '2vw',
    height: '80vh',
    overflowY: 'auto',
    padding: '1vw',
  },
  chartContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '1vw',
  },
  chartContainerProject: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '1vw',
    maxWidth: '100%',
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '25px',
    lineHeight: '5px',
    color: '#0E2040',
    paddingBottom: '3vw',
    paddingTop: '1vw',
  },
  prjTitle: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '25px',
    lineHeight: '5px',
    color: '#0E2040',
  },
}));

function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hasFetched = useRef(false);
  const [responseMessage, setResponseMessage] = useState('');
  const token = useSelector((state) => state.account.token);
  const dashboardData = useSelector((state) => state.account.dashboardData);

  const totalTasksState = dashboardData.total_tasks_per_state || {};
  const totalTasksPer = dashboardData.total_tasks || 0;
  const totalProjectsState = dashboardData.total_projects_per_state || {};
  const totalProjectsPer = dashboardData.total_projects || 0;
  const saveToken = localStorage.getItem('token');

  const savedRtl = localStorage.getItem('rtl');

  const dashboardFetch = async (page, name) => {
    const comingDashboardResponse = await getDashboard(
      !token ? saveToken : token,
      page,
      name
    );

    if (comingDashboardResponse.status === 200) {
      dispatch(
        saveDashboardData({ ...comingDashboardResponse.data.data })
      );
      setResponseMessage(comingDashboardResponse.data.message);
    }
  };

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      dashboardFetch();
    }
  }, []);

  const projectsData = {
    labels: savedRtl === 'true' ? ['جديد', 'قيد التنفيذ', 'مكتمل', 'ملغى'] : ['New', 'In Progress', 'Completed', 'Cancelled'],
    datasets: [
      {
        label: savedRtl === 'true' ? 'نسبة مئوية(%)' : 'Percentage(%)',
        data: [
          ((totalProjectsState.new / totalProjectsPer) * 100).toFixed(0),
          ((totalProjectsState.in_progress / totalProjectsPer) * 100).toFixed(0),
          ((totalProjectsState.completed / totalProjectsPer) * 100).toFixed(0),
          ((totalProjectsState.canceled / totalProjectsPer) * 100).toFixed(0),
        ],
        backgroundColor: ['#1E90FF', '#FFA500', '#28A745', '#DC3545'],
        hoverOffset: 4
      },
    ],
    responsive: [
      {
        breakpoint: 580,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  const tasksData = {
    labels: savedRtl === 'true' ? ['مكتمل', 'قيد التنفيذ', 'قيد الانتظار(DMC)', 'قيد الانتظار(العميل)'] : ['Completed', 'In Progress', 'DMC Pending', 'Client Pending'],
    datasets: [
      {
        label: savedRtl === 'true' ? 'نسبة مئوية(%)' : 'Percentage(%)',
        data: [
          ((totalTasksState.pending / totalTasksPer) * 100).toFixed(0),
          ((totalTasksState.c_pending / totalTasksPer) * 100).toFixed(0),
          ((totalTasksState.in_progress / totalTasksPer) * 100).toFixed(0),
          ((totalTasksState.completed / totalTasksPer) * 100).toFixed(0),
        ],
        backgroundColor: ['#1E90FF', '#FFA500', '#28A745', '#DC3545'],
        hoverOffset: 4
      },
    ],
    responsive: [
      {
        breakpoint: 580,
        options: {
          legend: {
            show: true,
          },
        },
      },
    ],
  };

  const getChartData = (project) => Object.values(project.stage_percentage);

  const labels = savedRtl === 'true' ? ['مكتمل', 'قيد التنفيذ', 'قيد الانتظار(DMC)', 'قيد الانتظار(العميل)'] : ['Completed', 'In Progress', 'DMC Pending', 'Client Pending'];
  const renderTasksPerProject = (project) => {
    if (dashboardData.projects) {
      return {
        labels,
        datasets: [
          {
            label: savedRtl === 'true' ? 'نسبة مئوية(%)' : 'Percentage(%)',
            data: getChartData(project).map((item) => item.toFixed(0)),
            backgroundColor: ['#1E90FF', '#FFA500', '#28A745', '#DC3545'],
            hoverOffset: 4
          },
        ],
        responsive: [
          {
            breakpoint: 580,
            options: {
              legend: {
                show: true,
              },
            },
          },
        ],
      };
    }
    return null;
  };

  return (
    <>
      {responseMessage === '' || dashboardData?.length === 0 ? (
        <LinearProgress />
      ) : (
        <Box className={classes.scrollableSection}>
          {responseMessage !== 'Success' || dashboardData?.length === 0 ? (
            <Typography className={classes.title}>{responseMessage}</Typography>
          ) : (
            <>
              <Grid container spacing={1}>
                <Grid lg={6} md={12} xs={12}>
                  <Typography className={classes.title}>{savedRtl === 'true' ? 'المشاريع' : 'Total Projects'}</Typography>
                  <Box className={classes.chartContainer}>
                    <Chart type="pie" data={projectsData} height={370} />
                  </Box>
                </Grid>

                <Grid lg={6} md={12} xs={12}>
                  <Typography className={classes.title}>{savedRtl === 'true' ? 'المهام' : 'Total Tasks'}</Typography>
                  <Box className={classes.chartContainer}>
                    <Chart type="pie" data={tasksData} height={370} />
                  </Box>
                </Grid>
              </Grid>

              <Typography className={classes.title}>All Projects</Typography>
              <Grid container spacing={2}>
                {dashboardData.projects
                  ?.filter((project) => Object.keys(project?.stage_percentage)?.length > 0)
                  .map((project, index) => (
                    <Grid item xs={12} lg={4} md={6}>
                      <Box key={index} className={classes.chartContainerProject}>
                        <Typography className={classes.prjTitle}>{project.project_name}</Typography>
                        <Chart type="bar" data={renderTasksPerProject(project)} height={300} />
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </>
          )}
        </Box>
      )}
    </>
  );
}

export default Dashboard;
